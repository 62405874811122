import './TopNav.css';
import React from 'react';
import NavButton from './NavButton';

const TopNav = () => {
  return (
    <div className='top-nav'>
      <nav>
        <NavButton path={'/'} label={'Strona główna'} ></NavButton>
        <NavButton path={'/offer'} label={'Oferta'} ></NavButton>
        <NavButton path={'/about'} label={'O mnie'} ></NavButton>
        <NavButton path={'/portfolio'} label={'Portfolio'} ></NavButton>
        <NavButton path={'/contact'} label={'Kontakt'} ></NavButton>
      </nav>
    </div>
  )
}

export default TopNav;