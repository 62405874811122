import React from 'react';
import Carousel from 'react-elastic-carousel';
import './Slider.css';
import SliderItem from './SliderItem';

const Slider = () => {

  const carouselRef = React.useRef(null);
  return (
    <Carousel
      itemsToShow={1}
      showArrows={false}
      transitionMs={1000}
      enableAutoPlay={true}
      autoPlaySpeed={3000}
    >
      <SliderItem
        imgSrc={'/images/books-slider-holder.jpg'}
        description={'Opis zdjęcia'} >
      </SliderItem>
      <SliderItem
        imgSrc={'/images/books-slider-holder.jpg'}
        description={'Opis zdjęcia'} >
      </SliderItem>
      <SliderItem
        imgSrc={'/images/books-slider-holder.jpg'}
        description={'Opis zdjęcia'} >
      </SliderItem>
    </Carousel>
  )
}

export default Slider;