import React from 'react';

const SliderItem = (props) => {

  const imgSrc = props.imgSrc;
  const description = props.description;

  return (
    <div className='slider-item__wrapper'>
      <div className='slider-item__img-holder'>
        <img src={imgSrc} width='800px' height='400px' />
      </div>
      <div className='slider-item__description'>
        <a>{description}</a>
      </div>
    </div>
  )
}

export default SliderItem;