import React, { useEffect } from 'react'
import './MainPage.css'
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from './Slider/Slider';

const MainPage = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);
  return (
    <div>
      <div className='main-section-grey'>
        <section className='main-section-content'>
          <Slider />
        </section>
      </div>
      <div className='main-section-light-grey'>
        <section className='main-section-content' data-aos='fade-up'>

        </section>
      </div>
      <div className='main-section-grey'>
        <section className='main-section-content' data-aos='fade-up'>

        </section>
      </div>
      <div className='main-section-light-grey'>
        <section className='main-section-content' data-aos='fade-up'>

        </section>
      </div>
    </div>
  )
}

export default MainPage;