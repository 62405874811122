import './App.css';
import Footer from './components/Footer/Footer';
import TopNav from './components/TopNav/TopNav';
import React, { useEffect } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import MainPage from './components/MainPage/MainPage';
import AboutPage from './components/AboutPage/AboutPage';
import OfferPage from './components/OfferPage/OfferPage';
import PortfolioPage from './components/PortfolioPage/PortfolioPage';
import ContactPage from './components/ContactPage/ContactPage';


function App() {
  return (
    <BrowserRouter>
      <div className='App'>
        <TopNav />
        <div className='content-wrapper' >
          <Routes>
            <Route path='/' element={<MainPage />} />
            <Route path='/about' element={<AboutPage />} />
            <Route path='/offer' element={<OfferPage />} />
            <Route path='/portfolio' element={<PortfolioPage />} />
            <Route path='/contact' element={<ContactPage />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
