import './Footer.css'
import React from 'react';

const Footer = () => {
  return (
    <footer className='bot-footer'>
      <a className='bot-footer__cp-claim'>&copy; 2022 Marek Jadczak</a>
    </footer>
  )
}

export default Footer;